interface ErrorComponent {
    message: string;
    onClose: () => void;
  }
  
  const ErrorComponent: React.FC<ErrorComponent> = ({ message, onClose }) =>{
    return (
      <>
        <div className="modal-overlay">
          <div className="main-modal-wrapper">
            <div className="bg-white w-[750px] rounded-[30px] ">
              <div>
                <div className="pt-4 pb-1 py-4 flex items-center justify-center">
                  <img
                    src="./assets/icons/information.png"
                    alt="email-icon"
                    className="justify-center text-center flex w-[180px] h-[180px]"
                  />
                </div>
  
                <div className="flex items-center justify-center">
                  <p className="text-black font-inter-font text-[45px] font-[600] text-center">
                    {message}
                  </p>
                </div>
  
                <div className="flex items-center justify-center pt-[50px]">
                  <div className="m-6 justify-center">
                    <button
                      className="rounded-[66px] bg-[#d42525] h-[89px] w-[200px] text-[#ffffff] font-inter-font text-[32px] font-[600]"
                      onClick={onClose}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  export default ErrorComponent;