import { FC } from "react";

const Loader: FC<{
  className?: string;
  variant?: "SMALL" | "MEDIUM" | "LARGE" | "FULL";
}> = ({ className, variant = "SMALL" }) => {
  return (
    <div
      className={`animate-spin rounded-full border-r-2 ${
        variant === "SMALL"
          ? "w-5 h-5"
          : variant === "MEDIUM"
          ? "h-10 w-10"
          : variant === "LARGE"
          ? "h-32 w-32"
          : "w-full h-full"
      } border-black ${className}`}
    >
      &nbsp;
    </div>
  );
};

export default Loader;
